<app-page-breadcrumb [pageInfo]="pageInfo" [isModal]="isModal"></app-page-breadcrumb>
<div class="modal-body pt-4 mb-4">
  <div class="card">
    <div class="card-body border-0 row">
      <div class="col-md-12">
        @if(roleFormData.length){
        <app-form-generator #basicForm [formData]="roleFormData">
        </app-form-generator>
        }
      </div>
      <div class="text-center">
        <p-button (click)="close()" [disabled]="loading" severity="danger" label="Close" icon="pi pi-times"
          class="me-2"></p-button>
        <p-button (click)="saveData()" [loading]="loading" severity="success"
          label="Save{{userData?.id ? ' Changes' : ''}}" icon="pi pi-check-circle"></p-button>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthApiService } from 'src/app/core/service/auth-api.service';
import { NavigationService } from 'src/app/core/service/navigation.service';
import { RESPONSE_CODE } from 'src/app/helper/class/app-constant';
import { UrlServices } from 'src/app/helper/class/url-services';
import { arrayGroupBy, cloneData, isEmailUniqueValidation, isEmptyObj } from 'src/app/helper/class/utilityHelper';
import { pageInfo } from 'src/app/helper/interface/menu-interface';
import { formBuilder, ResponseData } from 'src/app/helper/interface/response';
import { AlertService } from 'src/app/helper/service/alert.service';
import { AuthService } from 'src/app/helper/service/auth.service';
import { MasterApiService } from 'src/app/modules/master/service/master-api.service';
import { FormGeneratorComponent } from 'src/app/shared/form/component/form-generator/form-generator.component';
import { ModalService } from 'src/app/shared/service/modal.service';
import { USER_ROLE_FORM } from '../../helper/user_form';
import { groupModule } from '../../page/role-permission/role-permission.component';
import { UserApiService } from '../../services/user-api.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  roleFormData: formBuilder[] = []
  urlService = UrlServices.PAGE_URL;
  loading: boolean = false;
  userData: any = {};
  submitted: boolean = false;
  public onClose: Subject<boolean> = new Subject();
  userId: any;
  pageInfo: pageInfo = { title: '' }
  isModal: boolean = false;

  @ViewChild('basicForm') basicForm: FormGeneratorComponent | undefined;
  constructor(private userApi: UserApiService,
    private authApi: AuthApiService,
    private auth: AuthService,
    private modalService: ModalService,
    private navigation: NavigationService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.queryParams.id || '';
    this.pageInfo = {
      title: `${this.userId ? 'Update ' : 'Add '} User Detail`,
      buttonShowBtn: this.auth.checkPermission('USER', 'VIEW_ALL'),
      button: { url: this.urlService.USER.LIST.URL, title: 'User List' }
    }
    this.initform()
    if (this.userId) {
      this.getUserData();
    }
  }

  initform() {
    this.roleFormData = this.userId ? cloneData(USER_ROLE_FORM).map((a: formBuilder) => { if (['user_name', 'password'].includes(a.colName)) { a.validator = [] }; return a }) : cloneData(USER_ROLE_FORM);
  }

  setInput(data) {
    if (data.type == 'MY_PROFILE') {
      const user = this.auth.currentUserValue;
      this.isModal = true;
      this.userData = user;
      this.userId = user.user_id;
      this.pageInfo.title = 'My Profile';
      this.initform()
      this.basicForm?.setFormData(this.roleFormData);
      this.basicForm?.toogleDisableByCtrl('role_id', true)
      this.mapData();
    }
  }

  getUserData() {
    if (this.userId) {
      this.userApi.getUserData(this.userId).then((res: ResponseData | any) => {
        if (res.statusCode == RESPONSE_CODE.SUCCESS) {
          this.userData = res.result;
          this.mapData();
        }
      })
    }
  }

  mapData() {
    this.basicForm?.toogleDisableByCtrl('user_name', true)
    if (Array.isArray(this.userData?.role) && this.userData?.role?.length) {
      this.userData.role_id = this.userData.role[0].role_id;
    }
    setTimeout(() => {
      this.basicForm?.setData(this.userData)
    }, 800);
  }

  close(status = false) {
    if (this.isModal) {
      this.onClose.next(status);
      this.modalService.close()
    } else {
      this.navigation.back();
    }
  }

  apiPayload() {
    const payload: any = this.basicForm?.getFormValue() || {};
    if (payload.role_id) {
      payload['role'] = [];
      payload['role'].push({ role_id: payload.role_id });
    }
    return payload;
  }

  saveData() {
    this.submitted = true;
    if (!this.basicForm?.isValid()) {
      this.alertService.showToast('Make Sure All Fields Are Valid', 'error');
      return
    }
    this.loading = true;
    this.userApi.saveUser(this.apiPayload()).then((res: ResponseData | any) => {
      if (res.statusCode == RESPONSE_CODE.SUCCESS) {
        this.alertService.showToast('User details has been saved..', 'success');
        this.close(true);
      } else {
        this.alertService.showToast('', 'error');
      }
    }).finally(() => {
      this.loading = false;
    })
  }
}
export interface masterData {
  [key: string]: Array<any>
};
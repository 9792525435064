import { VALIDATOR_PATTERNS } from "src/app/helper/class/app-constant";
import { formBuilder } from "src/app/helper/interface/response";

export const USER_ROLE_FORM: formBuilder[] = [
    {
        colName: 'user_id',
        title: '',
        hidden: true,
    },
    {
        colName: 'user_name',
        title: 'Username',
        validator: [
            { name: 'required' },
            { name: 'pattern', funValue: VALIDATOR_PATTERNS.EMAIL, error: 'Username Should be valid' },
            { name: 'async', funName: 'isUniqueUserNameValidation', is_async: true, error: 'Username Already Exists' }
        ]
    },
    {
        colName: 'password',
        title: 'Password',
        type: 'PASSWORD',
        validator: [{ name: 'required' }],
    },
    {
        colName: 'email_id',
        title: 'Email Id',
        validator: [
            { name: 'required' },
            { name: 'pattern', funValue: VALIDATOR_PATTERNS.EMAIL, error: 'Email Should be valid' },
        ]
    },
    {
        colName: 'fname',
        title: 'First Name',
        validator: [{ name: 'required' }],
    },
    {
        colName: 'lname',
        title: 'Last Name',
    },
    {
        colName: 'role_id', title: 'User Role', apiTblName: 'role', type: 'select', selectKeyName: 'roleName',
        groupTitle: 'Role Management',
        validator: [{ name: 'required', error: '' }]
    },
]